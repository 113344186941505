import dayjs from "dayjs";

const beforeSave = (sample) => {
  let data = {};
  console.log("-> Save sample:", sample);
  Object.keys(sample).map((label) => {
    let v = sample[label];
    if (Object.prototype.toString.call(v) === "[object Date]") v = dayjs(v);
    if (v && v.$isDayjsObject) v = v.format("DD.MM.YYYY");
    data[label] = v;
  });
  return data;
};

export { beforeSave };
