import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import FullEditor from "../components/FullEditor";
import Records from "../components/Records";
import Editor from "../components/Editor";
import { beforeSave } from "../parts/functions";
import FilterButton from "../parts/FilterButton";
import { FiltersList, filterOptions } from "../parts/FiltersList";
import locale from "../i18n/locale";

class PrototypePage extends Records {
  constructor(props) {
    super(props);
    console.log("Page:", props.page);
    console.log("Authenticated:", props.authenticated);
    this.state.inputKey = 0;
    this.timer = null;

    let s = location.search.split("s=");
    if (s.length > 1) {
      this.state.filter_quick = decodeURIComponent(s[1]);
    }
  }

  onStart() {
    //super();
    console.log("location.pathname", location.pathname);
  }

  modify(obj, read_only = false) {
    this.that.open(
      {
        title: read_only ? obj.code || locale.view : locale.edit,
        body: (
          <FullEditor
            table={this.props.url}
            read_only={read_only}
            close={() => this.that.close("modal")}
            header={this.state.header}
            relationships={this.state.relationships}
            that={this.that}
            target={obj}
            authenticated={this.props.authenticated}
          />
        ),
      },
      "modal",
      () => {
        this.load();
      }
    );
  }

  create() {
    this.that.open(
      {
        title: locale.create,
        body: (
          <Editor
            table={this.props.url}
            close={() => this.that.close("modal")}
            header={this.state.header}
            that={this.that}
          />
        ),
      },
      "modal",
      () => {
        this.load();
      }
    );
  }

  upload(e) {
    const files = Array.from(e.target.files);
    if (files.length == 0) return;

    const fd = new FormData();
    fd.append("file", files[0]);
    fd.append("table", this.props.url);

    this.loader.get("upload", fd, (url, data) => {
      this.setState((s) => {
        s.inputKey += 1;
        return { inputKey: s.inputKey };
      });
      if (data.error) {
        return this.props.that.snack(locale[data.error] || data.error, "error");
      }
      if (data.result) {
        return this.props.that.snack(
          locale[data.result] || data.result,
          "success"
        );
      }
      console.log("data", data);
    });
  }

  export() {
    this.loader.download(
      "export",
      {
        columns: this.state.visibility,
        filter: this.state.filter,
        table: this.props.url,
      },
      this.props.page.many + ".tsv"
    );
  }

  render() {
    return this.state.init ? (
      <>
        {this.state["loading:parser"] ? (
          <div className="full-page-loading">
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        <div className="header-line">
          <div className="custom-filters">
            <h2>{this.props.page.many}</h2>
            <div className="quick-search">
              <TextField
                size="small"
                label="Quick search"
                defaultValue={this.state.filter_quick}
                onChange={(e) => {
                  clearTimeout(this.timer);
                  //if (e.target.value.length < 2) return;
                  this.timer = setTimeout(() => {
                    console.log("Quick search: ", e.target.value);
                    this.setState({ filter_quick: e.target.value }, () =>
                      this.load()
                    );
                  }, 350);
                }}
              />
            </div>
            <FilterButton
              add={(head) => {
                let select = filterOptions(head.type);
                this.setState(
                  (s) => {
                    s.filter.push({
                      col: head,
                      select: select,
                      operator: select[0] ? select[0].k : "=",
                      value: head.valueOptions ? head.valueOptions[0] : "",
                    });
                    return s;
                  },
                  () => this.load()
                );
              }}
              header={this.state.header}
            />
          </div>
          <Stack spacing={2} direction="row">
            {this.state.selected.length > 0 &&
            this.props.authenticated == "admin" ? (
              <Button
                onClick={() => this.remove(this.state.selected)}
                variant="contained"
                color="error"
              >
                {locale.delete} ({this.state.selected.length})
              </Button>
            ) : (
              ""
            )}
            {this.props.authenticated == "admin" ? (
              <>
                <Button onClick={() => this.create()} variant="outlined">
                  {locale.create}
                </Button>
                <Button onClick={() => this.export()} variant="outlined">
                  {locale.export}
                </Button>
                <Button
                  component="label"
                  variant="outlined"
                  key={`upload-${this.state.inputKey}`}
                >
                  {locale.import}
                  <input onChange={(e) => this.upload(e)} type="file" hidden />
                </Button>
              </>
            ) : (
              ""
            )}
          </Stack>
        </div>
        <FiltersList that={this} />
        <DataGrid
          autoHeight
          key={this.props.url}
          columns={this.state.header}
          rows={this.state.items}
          rowCount={this.state.total || 0}
          pagination
          localeText={{ noRowsLabel: "No data" }}
          pageSizeOptions={[20, 50, 100]}
          initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
          sortingMode="server"
          paginationMode="server"
          checkboxSelection={this.props.authenticated == "admin"}
          disableRowSelectionOnClick
          processRowUpdate={(upd, old) => {
            this.loader.get(
              "update",
              {
                sample: beforeSave(upd),
                table: this.props.url,
              },
              (url, data) => {
                this.load();
              }
            );
            return upd;
          }}
          onRowSelectionModelChange={(sel) => this.rowSelection(sel)}
          onPaginationModelChange={(obj) => {
            let p = { page: obj.page + 1, limit: obj.pageSize };
            this.setState({ page: p }, () => this.load());
          }}
          onSortModelChange={(obj) => {
            let p = { page: 1, limit: 20 };
            let s = obj[0] ? obj[0] : {};
            this.setState({ page: p, sort: s }, () => this.load());
          }}
          columnVisibilityModel={this.state.visibility}
          onColumnVisibilityModelChange={(model) =>
            this.setState({ visibility: model })
          }
          onFilterModelChange={(obj) => {
            this.setState(
              {
                page: { page: 1, limit: 20 },
                filter: obj.items ? obj.items[0] : {},
              },
              () => this.load()
            );
          }}
        />
      </>
    ) : (
      ""
    );
  }
}

export default PrototypePage;
