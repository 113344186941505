import React from "react";

import FolderIcon from "@mui/icons-material/Folder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DescriptionIcon from "@mui/icons-material/Description";

import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Loader from "./Loader";

class FileBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      root: "/",
      active: false,
      opened: {},
      selected: {},
      value: this.props.value || "",
      default_value: this.props.default || false,
    };
    this.get = this.get.bind(this);
    this.loader = new Loader(this);
    this.ref = props.refData || React.createRef();
  }

  get(item) {
    this.setState(
      (prevState) => {
        let opened = prevState.opened;
        opened[item.path] = !opened[item.path];
        return { opened: opened };
      },
      () => {
        if (!this.state.opened[item.path]) return;
        this.loader.get("browser", { path: item.path }, (p, data) => {
          this.setState({ [`ls${item.path}`]: data });
          console.log("this.state", this.state);
        });
      }
    );
  }

  componentDidMount() {
    if (this.props.root) {
      this.setState({ root: this.props.root }, () =>
        this.get({ path: this.state.root })
      );
    } else {
      this.get({ path: this.state.root });
    }
  }

  renderItems(data) {
    const Add = (item) => {
      this.setState((prevState) => {
        if (prevState["selected"][item.path]) {
          delete prevState["selected"][item.path];
        } else {
          prevState["selected"][item.path] = item.type;
        }
        return { selected: prevState["selected"] };
      });
    };

    const Select = (item) => {
      this.get(item);
      this.setState({ selected: { [item.path]: item.type } });
    };

    const items = data.map((item, k) => {
      if (this.props.ext && item.type === "file") {
        if (item.name.substr(-this.props.ext.length) != this.props.ext)
          return "";
      }
      return (
        <div
          key={k}
          className={`item type-${item.type} ${
            this.state.opened[item.path] ? "opened" : ""
          }`}
        >
          <div
            onClick={(e) => (e.ctrlKey || e.metaKey ? Add(item) : Select(item))}
            className={`meta`}
          >
            <DescriptionIcon className={"file"} />
            <FolderIcon className={"folder"} />
            <div
              className={`name ${
                this.state.selected[item.path] ? "selected" : ""
              }`}
            >
              {item.name} <KeyboardArrowDownIcon className={"arrow"} />
            </div>
          </div>
          <div className={"children"}>
            {this.state.opened[item.path]
              ? this.renderItems(this.state[`ls${item.path}`] || [])
              : ""}
          </div>
        </div>
      );
    });

    return <div>{items}</div>;
  }

  render() {
    return (
      <FormControl className="file-browser input-block" fullWidth>
        <div className={"browser-wrapper"}>
          <TextField
            label={this.props.label}
            ref={this.ref}
            value={this.state.value || ""}
            size={`${this.props.size || "small"}`}
            onClick={() => this.setState({ active: true })}
            type="text"
            placeholder={"Выбрать"}
            readOnly
          />

          <div className={`selector ${this.state.active ? "active" : ""}`}>
            <div className={"tree"}>
              {this.renderItems(this.state["ls" + this.state.root] || [])}
            </div>
            <div className={"footer"}>
              <span>
                <Button
                  onClick={() => {
                    this.setState({ active: false });
                  }}
                  variant="outlined"
                  size="small"
                >
                  Отмена
                </Button>{" "}
                &nbsp;
                <Button
                  onClick={() => {
                    this.setState(
                      { active: false, value: "", default_value: false },
                      () => {
                        if (this.props.onChange) this.props.onChange("");
                      }
                    );
                  }}
                  variant="outlined"
                  color="error"
                  size="small"
                >
                  Очистить
                </Button>
              </span>
              <Button
                onClick={() => {
                  let v = Object.keys(this.state.selected)[0] || "";
                  this.ref.current.value = v;
                  if (this.props.onChange) this.props.onChange(v);
                  this.setState({
                    active: false,
                    value: v,
                    default_value: false,
                  });
                }}
                size="small"
                color="success"
                variant="outlined"
              >
                Выбрать
              </Button>
            </div>
          </div>
        </div>
      </FormControl>
    );
  }
}

export default FileBrowser;
