import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import Login from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import DrawIcon from "@mui/icons-material/Draw";
import locale from "../i18n/locale";

const settings = ["user", "events"];

function ResponsiveAppBar({ tabs, access, title, authenticated }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <nav className={"nav-panel"}>
        <Toolbar disableGutters>
          <DrawIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            href="/"
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {tabs.map((url) =>
                access[url] ? (
                  <MenuItem
                    key={url}
                    component={NavLink}
                    to={`/${url}`}
                    onClick={handleCloseNavMenu}
                    className={`tab-${url}`}
                  >
                    <Typography textAlign="center">
                      {access[url].many || access[url].title}
                    </Typography>
                  </MenuItem>
                ) : (
                  ""
                )
              )}
            </Menu>
          </Box>
          <DrawIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {tabs.map((url) =>
              access[url] ? (
                <Button
                  key={url}
                  component={NavLink}
                  to={`/${url}`}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {access[url].many || access[url].title}
                </Button>
              ) : (
                ""
              )
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                size="large"
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
              >
                <SettingsApplicationsIcon sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((url) => {
                if (!access[url]) return "";
                return (
                  <MenuItem
                    key={url}
                    component={NavLink}
                    to={`/${url}`}
                    onClick={handleCloseUserMenu}
                  >
                    <Typography textAlign="center">
                      {access[url].title}
                    </Typography>
                  </MenuItem>
                );
              })}
              {authenticated == "guest" ? (
                <MenuItem
                  onClick={() => {
                    window.location = "/login";
                  }}
                >
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  {locale.log_in}
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    window.location = "/logout";
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {locale.logout}
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </nav>
    </AppBar>
  );
}
export default ResponsiveAppBar;
