import React from "react";
import Loader from "../components/Loader";
import { Alert, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class SubmitPage extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.state = { index: 1, result: false, avail: false, GC: false };
    this.ref = {};
    this.buttons = ["Variants", "Studies", "Cases", "Observations"];
    this.buttons.map((btn) => {
      this.state[btn] = undefined;
    });
  }

  componentDidMount() {}

  send() {
    const fd = new FormData();
    this.buttons.map((btn) => {
      fd.append(btn, this.state[btn]);
    });
    this.setState({ avail: false });
    fd.append("GC", this.state.GC);
    this.loader.get("submit", fd, (url, data) => {
      this.setState({ result: "Your files have been sent. Thaks!" });
    });
  }

  render() {
    return (
      <>
        <div className="header-line">
          <div className="custom-filters">
            <h2>PLoV-db / Submit</h2>
          </div>
        </div>
        <div className="index-content">
          <p>Send us your datasets and we will add them to our repository!</p>
          <div className="submit-form">
            {this.buttons.map((btn) => (
              <div key={`upload-${btn}`} className="block">
                <Button
                  component="label"
                  variant="outlined"
                  key={`upload-${btn}-${this.state.index}`}
                >
                  {btn}
                  <input
                    onChange={(e) => {
                      this.setState({ [btn]: e.target.files[0] }, () => {
                        this.setState({ avail: true });
                      });
                    }}
                    type="file"
                    hidden
                  />
                </Button>
                {this.state[btn] ? (
                  this.state[btn].name
                ) : (
                  <span className="hint">
                    <span>File not selected</span>
                    <a target="_blank" href={`./examples/${btn}.tsv`}>
                      example
                    </a>
                  </span>
                )}
              </div>
            ))}
            <div className="gc">
              <ReCAPTCHA
                sitekey="6LdL5BEqAAAAAClwGNsui0lQoXdYXWGHhD7c5j7P"
                onChange={(e) => {
                  this.setState({ GC: e });
                }}
              />
            </div>
            {this.state.result ? (
              <Alert>{this.state.result}</Alert>
            ) : (
              <Button
                onClick={() => this.send()}
                variant="contained"
                color="success"
                disabled={!this.state.avail || !this.state.GC}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SubmitPage;
