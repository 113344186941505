import React from "react";
const d3 = require("d3");

const gradients = {
  gpos25: { 3: "#C8C8C8", 15: "#e6e6e6", 60: "#ccc" },
  gpos50: { 3: "#AAA", 15: "#BBB", 66: "#999" },
  gpos75: { 3: "#999", 15: "#999", 66: "#777" },
  gpos100: { 3: "#777", 15: "#999", 66: "#444" },
  acen: { 3: "#FEE", 15: "#FEE", 66: "#FDD" },
  gneg: { 70: "#FFF", 95: "#e6e6e6", 100: "#FFF" },
};

function KaryotypeBar(el, map, width, objects) {
  const node = d3.select(el).node();
  if (!node) return;
  console.log("Karyotype [+]", width, objects);

  d3.select(el).html("");
  const svg = d3
    .select(el)
    .append("svg")
    .attr("overflow", "visible")
    .attr("style", "width: " + width + "px")
    .attr("class", "karyotype-svg")
    .attr("viewBox", [0, 0, width, 58]);

  const centre = map.filter((box) => box[3] === "acen");
  const chr_size = map[map.length - 1][1];
  if (centre.length === 0) return;

  const bands = svg
    .append("g")
    .attr("clip-path", "url(" + el + "-clip)")
    .attr("class", "bands");

  const x = d3.scaleLinear().range([0, width]).domain([0, chr_size]);

  let ticks = Math.max(1, Math.round(width / 120));
  let axis = d3.axisBottom(x).ticks(ticks);
  svg
    .append("g")
    .attr("class", "axis axis--x")
    .attr("transform", `translate(0, 46)`)
    .call(axis);

  const cen = x(centre[0][1]);
  const end = x(chr_size);
  const left = `M4,3 L${cen - 4 - 1},3 Q${cen + 4 - 1},15,${
    cen - 4 - 1
  },27 L4,27 Q-4,15,4,3`;
  const right = `M${cen + 4},3 L${end - 4 + 1},3 Q${end + 4 + 1},15,${
    end - 4 + 1
  },27 L${cen + 4},27 Q${cen - 4},15,${cen + 4},3`;

  svg.append("path").attr("class", "chr-border").attr("d", left);
  svg.append("path").attr("class", "chr-border").attr("d", right);

  const defs = svg.append("defs");

  const pattern = defs
    .append("pattern")
    .attr("width", "2")
    .attr("height", "1")
    .attr("patternUnits", "userSpaceOnUse")
    .attr("patternTransform", "rotate(-30 0 0)")
    .attr("id", "gvar");
  pattern.append("rect").attr("x", 0).attr("y", 0);
  pattern
    .append("line")
    .attr("x1", 0)
    .attr("y1", 0)
    .attr("x2", 0)
    .attr("y2", "100%");

  const cp = defs
    .append("svg:clipPath")
    .attr("id", el.replace("#", "") + "-clip");
  cp.append("path").attr("d", left);
  cp.append("path").attr("d", right);

  for (let k in gradients) {
    const gradient = defs
      .append("linearGradient")
      .attr("id", k)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", "100%");
    for (let offset in gradients[k]) {
      gradient
        .append("stop")
        .attr("offset", `${offset}%`)
        .attr("stop-color", gradients[k][offset]);
    }
  }

  objects.map((v) => {
    let left = x(v.pos) - 3;
    svg
      .append("circle")
      .attr("transform", `translate(${left}, 37)`)
      .attr("r", 6)
      .attr("stroke", "rgba(0,0,0,0.5)")
      .attr("fill", v.color);
  });

  let last_hint = -30;
  map.map((box) => {
    const dx = parseFloat(x(box[0]));
    const width = x(box[1] - box[0]);
    const mean = parseFloat(x(box[1] / 2 + box[0] / 2));

    bands
      .append("rect")
      .attr("transform", `translate(${dx}, 3)`)
      .attr("width", width)
      .attr("height", 24)
      .attr("fill", `url(#${box[3]})`)
      .attr("class", box[3]);

    if (width > 38 || mean - 38 > last_hint) {
      last_hint = mean;

      svg
        .append("line")
        .attr("class", "karyo-hints")
        .attr("x1", mean)
        .attr("y1", -4)
        .attr("x2", mean)
        .attr("y2", 8);

      svg
        .append("text")
        .attr("class", "karyo-hints")
        .attr("x", mean - (box[2].length * 5.1) / 2)
        .attr("y", -8)
        .text(box[2]);
    }
  });
}

export default KaryotypeBar;
