import React from "react";
import Loader from "../components/Loader";
import dayjs from "dayjs";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridActionsCellItem } from "@mui/x-data-grid";
import locale from "../i18n/locale";
import { Tooltip } from "@mui/material";

const PatchTooltip = ({ children, ...props }) => (
  <Tooltip {...props}>
    <span>{children}</span>
  </Tooltip>
);

class Records extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: {},
      types: {},
      header: [],
      relationships: [],
      items: [],
      init: false,
      count: undefined,
      filter: [],
      sort: {},
      page: { limit: 20, page: 1 },
      selected: [],
    };
    this.vt = this.props.vt ? JSON.parse(this.props.vt || "{}") : false;
    this.loader = new Loader(this);
    this.that = props.that;
    this.table = props.url;
    this.editable = this.props.page
      ? ["write", "own"].indexOf(this.props.page.level) !== -1
      : false;
  }

  remove(sel) {
    let text = `${locale.removed_item} #${sel[0]}`;
    if (sel.length > 1) text = `${locale.removed} (${sel.length})`;

    this.setState((s) => {
      s.items = s.items.filter((one) => sel.indexOf(one.id) === -1);
      return { items: s.items };
    });

    this.that.snack(
      text,
      "warning",
      () => {
        this.loader.get(
          "remove",
          {
            table: this.table,
            items: sel,
          },
          (u, data) => {
            this.load();
          }
        );
      },
      () => {
        this.load();
      }
    );
  }

  rowSelection(sel) {
    let items = this.state.items
      .filter((obj) => sel.indexOf(obj.id) !== -1)
      .map((e) => e.id);
    this.setState({ selected: items });
  }

  filterPreparation() {
    const fx = this.state.filter.map((f) => {
      if (f.col && f.col.type == "date")
        f.value = dayjs(f.value).format("DD.MM.YYYY");
      return f;
    });
    return fx;
  }

  load() {
    this.loader.get(
      "records",
      {
        page: this.state.page,
        filter_quick: this.state.filter_quick || undefined,
        filter: this.filterPreparation(),
        sort: this.state.sort,
        table: this.table,
      },
      (p, data) => {
        data.items = data.items.map((row) => {
          Object.keys(row).map((label) => {
            if (this.state.types[label] && this.state.types[label] == "date") {
              row[label] = dayjs(row[label], "DD.MM.YYYY").toDate();
            }
            if (row[label] == null) row[label] = "";
          });
          return row;
        });
        this.setState(data);
      }
    );
  }

  componentDidMount() {
    this.loader.get("summary", { table: this.table }, (p, data) => {
      let types = {};
      let header = [];
      let visibility = {};

      data.header.map((one) => {
        if (one.type == "date") {
          one.renderCell = (obj) => dayjs(obj.value).format("DD.MM.YYYY");
        }
        if (one.field != "id") {
          one.editable = true;
        }
        if (one.field == "acmg_class") {
          one.renderCell = (obj) => {
            let value = obj.value || "";
            return (
              <span className={`acmg-type-${value.replace(" ", "-")}`}>
                {value}
              </span>
            );
          };
        }
        if (one.field == "mutation_type") {
          one.renderCell = (obj) => {
            if (!this.vt || !this.vt[obj.value]) {
              return <span>{obj.value}</span>;
            }
            return (
              <PatchTooltip
                className="tooltip"
                title={this.vt[obj.value].desc}
                arrow
              >
                <span
                  className="boll"
                  style={{ background: this.vt[obj.value].color }}
                ></span>
                {obj.value}
              </PatchTooltip>
            );
          };
        }
        // this.props.vt
        // if (one.type == "password") {
        //   one.renderCell = (obj) => (
        //     <i style={{ color: "#666" }}>Сбросить пароль</i>
        //   );
        //   one.width = 200;
        // }
        header.push(one);
        types[one.field] = one.type;
        visibility[one.field] =
          one.visibility === undefined ? true : one.visibility;
        if (one.type == "foreign") one.visibility = false;
      });

      header.push({
        field: "actions",
        type: "actions",
        headerName: "",
        width: 100,
        getActions: (obj) => {
          let actions = [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label={locale.view}
              onClick={(e) => {
                this.modify(obj.row, true);
              }}
              color="inherit"
            />,
          ];
          if (this.props.authenticated == "admin") {
            actions.push(
              <GridActionsCellItem
                icon={<ModeEditIcon />}
                label={locale.edit}
                onClick={(e) => {
                  this.modify(obj.row);
                }}
                color="inherit"
              />
            );
          }
          return actions;
        },
      });

      this.setState(
        {
          relationships: data.relationships,
          visibility: visibility,
          types: types,
          header: header,
          init: true,
        },
        () => this.load()
      );
    });
  }
}

export default Records;
