import React from "react";
import Editor from "../components/Editor";
import RelationsTable from "./RelationsTable";
import { Divider } from "@mui/material";

class FullEditor extends Editor {
  constructor(props) {
    super(props);
    console.log("props.relationships", props.relationships);

    this.relations =
      props.relationships.length > 0 ? (
        <div className={"modal-body modal-relationships"}>
          <Divider component="hr" />
          {props.relationships.map((rel) => (
            <RelationsTable
              title={rel.title}
              parentTable={props.table}
              table={rel.table}
              sample={this.state.sample}
              key={rel.table}
              that={props.that}
              authenticated={props.authenticated}
            ></RelationsTable>
          ))}
        </div>
      ) : (
        ""
      );
  }
}

export default FullEditor;
