import axios from "axios";

class Loader {
  constructor(parent) {
    this.parent = parent;
    this.prefix =
      parent.prefix === undefined ? `${window.basename}api/` : parent.prefix;
    this.cancel = {};
  }

  download(path, data, name = false) {
    const obj = {
      url: this.prefix + path,
      method: "post",
      data: data || {},
      responseType: "blob",
    };

    axios(obj)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name ?? `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log("[STOP]", thrown.message);
        } else {
          console.log("[ERROR]", thrown.message);
          this.parent.setState({ [l]: false });
        }
      });
  }

  get(path, data, after, error) {
    if (this.cancel[path]) {
      this.cancel[path].cancel("Request canceled by user");
    }

    const CancelToken = axios.CancelToken;
    this.cancel[path] = CancelToken.source();

    const l = `loading:${path}`;
    this.parent.setState({ [l]: true });

    const obj = {
      url: this.prefix + path,
      method: "post",
      data: data || {},
      cancelToken: this.cancel[path].token,
    };

    axios(obj)
      .then((res) => {
        this.parent.setState({ ["data:" + path]: res.data });
        this.parent.setState({ [l]: false });
        if (after) after(path, res.data);
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log("[STOP]", thrown.message);
        } else {
          console.log("[ERROR]", thrown.message);
          if (error) error(thrown.message);
          this.parent.setState({ [l]: false });
        }
      });
  }
}

export default Loader;
