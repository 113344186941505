import React from "react";
import Loader from "./Loader";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

class AutocompleteRequest extends React.Component {
  constructor(props) {
    super(props);
    this.table = props.h.column.split(".")[0];
    this.state = {
      loading: props.value ? true : false,
      open: false,
      options: [],
      inputValue: props.value ? `ID${props.value}` : "",
      value: props.value ? `ID${props.value}` + "" : "",
    };
    this.loader = new Loader(this);
  }

  componentDidMount() {
    if (this.props.value) {
      this.loader.get(
        "details",
        { table: this.table, id: this.props.value },
        (url, data) => {
          this.setState({
            value: data._value,
            inputValue: data._value,
            loading: false,
          });
        }
      );
    } else {
      //this.variants("");
    }
    if (!this.props.readOnly) this.variants("");
  }

  variants(val) {
    this.setState({ loading: true });
    this.loader.get(
      "variants",
      { table: this.table, term: val },
      (url, data) => {
        data.map((one) => {
          one.label = one._value;
        });
        this.setState({ options: data, loading: false });
      }
    );
  }

  render() {
    if (this.props.readOnly) {
      return (
        <TextField
          size="small"
          readOnly={true}
          label={this.props.h.headerName}
          value={this.state.value}
          variant="outlined"
          disabled
        />
      );
    }

    return (
      <Autocomplete
        open={this.state.open}
        onOpen={() => {
          this.setState({ open: true });
        }}
        onClose={() => {
          this.setState({ open: false });
        }}
        onChange={(e, val) => {
          console.log("val", val);
          this.props.onChange(val && val.id ? val.id : 0);
          this.setState({ value: val });
        }}
        onInputChange={(e, val, reason) => {
          console.log("inputValue", val);
          this.setState({ inputValue: val });
          if (reason == "input") {
            this.props.onChange(val);
            this.variants(val);
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option._value == value || option._value === value._value;
        }}
        options={this.state.options}
        loading={this.state.loading}
        freeSolo
        filterOptions={(x) => x}
        value={this.state.value}
        inputValue={this.state.inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            required={!this.props.h.optional}
            size={this.props.size || "medium"}
            label={this.props.h.headerName}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
}

export default AutocompleteRequest;
