// EN
const locale = {
  create: "Create",
  delete: "Delete",
  save: "Save",
  close: "Close",
  cancel: "Cancel",
  logout: "Logout",
  view: "View",
  ok: "Ok",
  edit: "Edit",
  add_filter: "Add filter",
  number: "Number",
  string: "String",
  date: "Date",
  export: "Export (.tsv)",
  import: "Import (.tsv)",
  error_reading_file: "Error reading uploaded file",
  import_success: "Successfully imported!",
  log_in: "Log In",
  login: "Login",
  password: "Password",
  removed_item: "Object removed",
  removed: "Objects removed",
};

export default locale;
