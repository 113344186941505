import React from "react";
import Loader from "../components/Loader";
import { Alert, Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.state = {
      index: 1,
      result: false,
      avail: false,
      GC: false,
      message: "",
    };
    this.ref = {};
  }

  componentDidMount() {}

  send() {
    const fd = new FormData();
    this.setState({ avail: false });
    fd.append("message", this.state.message);
    fd.append("GC", this.state.GC);
    this.loader.get("submit-report", fd, (url, data) => {
      this.setState({ result: "Your message have been sent. Thaks!" });
    });
  }

  render() {
    return (
      <>
        <div className="header-index">
          <h2>About</h2>
          <p>
            PLoV is a database consisting of four main tables: Variants, Cases,
            Studies and Observations. Each of these tables provides detailed
            descriptive information about the variant and the settings and
            cases/families in which it was found. The main page provides
            information about nucleotide substitutions and the amino acid
            substitutions they lead to, as well as the gene and chromosome in
            which this SNP is located. Each variant has its own Variant ID which
            allows the user to link the detected nucleotide substitution with
            the case (family) in which it was found. In the Cases table, we
            tried to provide all significant information about the fetus,
            parents, observed phenotypes and pregnancy duration and results
            which were presented in the articles. Each case has its own Case ID
            which is associated with the Observations table (as well as Variant
            ID), which allows researchers to track the genotype of the parents
            and fetus. From the Cases section with the help of Study ID, users
            can get information about specific study in which this case was
            described. The Study table contains data about the cohort and method
            used in the article, as well as related information that allows to
            find the article.
          </p>
        </div>

        <div className="index-content">
          <div className="submit-form-messsage">
            <TextField
              fullWidth
              multiline
              rows={4}
              onChange={(e) => {
                this.setState({
                  message: e.target.value,
                  avail: e.target.value != "",
                });
              }}
              placeholder="If you find a problem, write to us about it!"
            />
            <div className="gc">
              <ReCAPTCHA
                sitekey="6LdL5BEqAAAAAClwGNsui0lQoXdYXWGHhD7c5j7P"
                onChange={(e) => {
                  this.setState({ GC: e });
                }}
              />
            </div>
            {this.state.result ? (
              <Alert>{this.state.result}</Alert>
            ) : (
              <Button
                onClick={() => this.send()}
                variant="contained"
                color="success"
                disabled={!this.state.avail || !this.state.GC}
              >
                Report a problem
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AboutPage;
