import React from "react";
import { BrowserRouter, Route, Routes, NavLink } from "react-router-dom";

import Loader from "./components/Loader";

import PrototypePage from "./pages/PrototypePage";
import IndexPage from "./pages/IndexPage";
import SubmitPage from "./pages/SubmitPage";
import AboutPage from "./pages/AboutPage";

import Modals from "./components/Modals";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

const ErrorPage = () => <div />;

class App extends Modals {
  constructor(props) {
    super(props);
    this.loader = new Loader(this);
    this.tabs = Object.keys(props.access).filter(
      (url) => ["settings"].indexOf(url) == -1
    );
    console.log("this.tabs", this.tabs);
    this.custom_page = ["submit", "about"];
    //this.tabs = ["sample", "sequence", "score"]; // .filter((t) => this.props.access[t]);
  }

  render() {
    return this.modalsWrapper(
      <BrowserRouter>
        <div className={"application"}>
          <ResponsiveAppBar
            tabs={this.tabs}
            access={this.props.access}
            authenticated={this.props.authenticated}
            title={"PLoV"}
          />
          <div className={"app-content"}>
            <Routes>
              {this.tabs
                .filter((url) => this.custom_page.indexOf(url) == -1)
                .map((url) => (
                  <Route
                    key={url}
                    path={"/" + url}
                    element={
                      <PrototypePage
                        key={url}
                        that={this}
                        url={url}
                        page={this.props.access[url]}
                        authenticated={this.props.authenticated}
                        vt={this.props.vt}
                      />
                    }
                  />
                ))}

              <Route path="/about" element={<AboutPage />} />
              <Route path="/submit" element={<SubmitPage />} />
              <Route path="" element={<IndexPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
