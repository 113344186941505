import React from "react";
import { createRoot } from "react-dom/client";

window.basename = "/";

const body = document.querySelector("body").dataset;

import sizes from "../scss/index.scss";
import App from "./App";

createRoot(document.getElementById("app")).render(
  <App
    access={JSON.parse(body.access)}
    authenticated={body.authenticated}
    vt={body.vt}
  />
);
if (module.hot) module.hot.accept();
