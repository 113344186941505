import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutocompleteRequest from "../components/AutocompleteRequest";
import "dayjs/locale/ru";
import locale from "../i18n/locale";

const filterOptions = (type) => {
  const options = [
    { m: "string,number", k: "=", v: "=" },
    { m: "string,number", k: "!=", v: "!=" },
    { m: "number", k: "<=", v: "Less or equal" },
    { m: "number", k: "<", v: "Less" },
    { m: "number", k: ">=", v: "More or equal" },
    { m: "number", k: ">", v: "More" },
    { m: "string", k: "contains", v: "Contains" },
    { m: "string", k: "startsWith", v: "Begin with" },
    { m: "date", k: "is", v: "On the selected day" },
    { m: "date", k: "after", v: "After" },
    { m: "date", k: "before", v: "Before" },
    { m: "date,string,number", k: "isNotEmpty", v: "is not empty" },
    { m: "date,string,number", k: "isEmpty", v: "is empty" },
  ];
  return options.filter((opt) => opt.m.indexOf(type) !== -1);
};

class FiltersList extends React.Component {
  constructor(props) {
    super(props);
  }

  // Types: date, text, number, foreign

  set(index, key, value, reload) {
    const parent = this.props.that;
    parent.setState(
      (s) => {
        s.filter[index][key] = value;
        console.log("SET " + key, value);
        return s;
      },
      () => (reload ? parent.load() : "")
    );
  }

  render_number(f, fk) {
    return (
      <TextField
        type="number"
        size="small"
        label={locale.number}
        value={f.value}
        onChange={(e) => this.set(fk, "value", e.target.value, true)}
        variant="outlined"
      />
    );
  }

  render_string(f, fk) {
    return (
      <TextField
        size="small"
        label={locale.string}
        value={f.value}
        onChange={(e) => this.set(fk, "value", e.target.value, true)}
        variant="outlined"
      />
    );
  }

  render_date(f, fk) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DateField
          size="small"
          label={locale.date}
          onChange={(val) => {
            this.set(fk, "value", val, val.$d != "Invalid Date");
          }}
          value={f.value}
          format="DD.MM.YYYY"
        />
      </LocalizationProvider>
    );
  }

  render_foreign(f, fk) {
    return (
      <AutocompleteRequest
        size="small"
        h={f.col}
        value={f.value}
        onChange={(obj) => {
          this.set(fk, "value", obj, true);
        }}
      ></AutocompleteRequest>
    );
  }

  render_singleSelect(f, fk) {
    return (
      <Select
        size="small"
        onChange={(e) => {
          this.set(fk, "value", e.target.value, true);
        }}
        value={f.value}
      >
        {f.col.valueOptions.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        ))}
      </Select>
    );
  }

  render() {
    const parent = this.props.that;

    return (
      <div className="filters-list">
        {this.props.that.state.filter.map((f, fk) => {
          if (!f.col) return "";
          console.log(f);
          return (
            <div key={fk} className="filter-block">
              <span className="title">{f.col.headerName}</span>
              {f.col.type != "foreign" && f.select.length ? (
                <Select
                  onChange={(e) =>
                    this.set(fk, "operator", e.target.value, true)
                  }
                  size="small"
                  value={f.operator}
                >
                  {f.select.map((opt) => (
                    <MenuItem key={opt.k} value={opt.k}>
                      {opt.v}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                ""
              )}
              {["isNotEmpty", "isEmpty"].indexOf(f.operator) === -1
                ? this[`render_${f.col.type}`]
                  ? this[`render_${f.col.type}`](f, fk)
                  : this[`render_string`](f, fk)
                : ""}
              <IconButton
                size="small"
                onClick={() => {
                  parent.setState(
                    (s) => {
                      s.filter.splice(fk, 1);
                      return s;
                    },
                    () => parent.load()
                  );
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </div>
          );
        })}
      </div>
    );
  }
}

export { FiltersList, filterOptions };
