import React from "react";
import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";
import { enUS } from "@mui/material/locale";

const theme = createTheme({}, enUS);
import CloseIcon from "@mui/icons-material/Close";
import locale from "../i18n/locale";

class Modals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: { show: false, body: null, title: null, after: null },
      mini: { show: false, body: null, title: null, after: null },
    };
  }

  open(obj, code, after = null) {
    this.setState({ [code]: { after: after, show: true, ...obj } });
  }

  close(code) {
    this.setState({ [code]: { show: false } }, this.state[code].after);
  }

  // variant = default | error | success | warning | info
  snack(message, variant, after = null, onStop = null) {
    let timer = setTimeout(() => {
      if (after) after();
    }, 5000);
    const action = (snackbarId) => (
      <Button
        variant="filled"
        size={"small"}
        onClick={() => {
          closeSnackbar(snackbarId);
          clearTimeout(timer);
          if (onStop) onStop();
        }}
      >
        {onStop ? locale.cancel : locale.ok}
      </Button>
    );
    enqueueSnackbar(message, { action, variant });
  }

  modalsWrapper(content) {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={8} autoHideDuration={5000}>
          {content}
          <Dialog
            open={this.state.modal.show}
            onClose={() => this.close("modal")}
            maxWidth={"xl"}
            scroll={"paper"}
          >
            <DialogTitle>
              {this.state.modal.title}
              <IconButton
                aria-label="close"
                onClick={() => this.close("modal")}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {this.state.modal.body}
          </Dialog>
          <Dialog
            open={this.state.mini.show}
            onClose={() => this.close("mini")}
            className="child-modal"
            maxWidth={"md"}
            scroll={"paper"}
          >
            <DialogTitle>
              {this.state.mini.title}
              <IconButton aria-label="close" onClick={() => this.close("mini")}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            {this.state.mini.body}
          </Dialog>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
export default Modals;
